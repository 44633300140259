const initialState = {
  selectedTab: null,
};
export const tabActionTypes = {
  ON_SET_TAB: "ON_SET_TAB",
};

export const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case tabActionTypes.ON_SET_TAB: {
      return { ...state, selectedTab: action.payload };
    }

    default:
      return state;
  }
};
