import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./common.scss";
const AntIcon = ({ size, color }) => (
  <LoadingOutlined
    style={{
      fontSize: size ? size : 24,
      color: color ? color : "black",
    }}
    spin
  />
);
const Spinner = ({ size, message }) => {
  return (
    <span>
      {message ? `${message}` : ``}
      <Spin indicator={<AntIcon size={size} />} />
    </span>
  );
};
export default Spinner;
