import { combineReducers } from "redux";
import {
  localStore,
  session,
  faqs,
  blogs,
  imagesGallary,
  videosGallary,
  flightSearch,
  reviews,
  packages,
  tabReducer,
} from "../reducers";
// import { session } from "../reducers/sessionManagement";

const appReducer = combineReducers({
  session,
  localStore,
  faqs,
  blogs,
  imagesGallary,
  videosGallary,
  flightSearch,
  reviews,
  packages,
  tabReducer,
});

const rootReducers = (state, action) => {
  return appReducer(state, action);
};
export default rootReducers;
