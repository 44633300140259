import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/store";
import ScrollToTop from "utilities/ScrollToTop";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { HelmetProvider } from "react-helmet-async";
// import { LightTheme, DarkTheme, ThemeProvider } from 'baseui';
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";
import "./scss/custom.scss";
import "./index.css"

const engine = new Styletron();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <StyletronProvider value={engine}>
      <ScrollToTop>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ScrollToTop>
    </StyletronProvider>
  </Provider>
);
reportWebVitals();
