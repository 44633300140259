import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MapComponent = compose(
  withProps({
    googleMapURL:
      // "https://maps.googleapis.com/maps/api/js?key=AIzaSyAR8UQowT94anF-BAxSfzeRaIK2cFYXbsk&&v=3.exp&libraries=geometry,drawing,places",
     "https://maps.googleapis.com/maps/api/js?key=AIzaSyB8I8YMbMLxRLboMreKRE1xihLgIy2ZiwI&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `475px` }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: `10px` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: 41.9906717, lng: -87.7024563 }}
  >
    {props.isMarkerShown && (
      <Marker
        defaultLabel="Chicago Hajj and Umrah"
        position={{ lat: 41.9906717, lng: -87.7024563 }}
      />
    )}
  </GoogleMap>
));
export default MapComponent;
