import { post, patch, get, deletee } from "./index";

// FAQ Types
export const addNewFaqType = (data) => {
  const request = {
    subUrl: `/faqTypes`,
    data,
  };
  return post(request);
};

export const getFaqTypeList = (params) => {
  const request = {
    subUrl: `/website/getAllFaqType`,
    params,
  };
  return get(request);
};

export const updateFaqTypes = (data) => {
  const request = {
    subUrl: `/faqTypes/${data._id}`,
    data,
  };
  return patch(request);
};

export const deleteFaqTypes = (id) => {
  const request = {
    subUrl: `/faqTypes/${id}`,
  };
  return deletee(request);
};

//FAQs

export const addNewFaq = (data) => {
  const request = {
    subUrl: `/faq`,
    data,
  };
  return post(request);
};

export const getFaqs = (params) => {
  const request = {
    subUrl: `/website/getAllFaq`,
    params,
  };
  return get(request);
};

export const updateFaqs = (data) => {
  const request = {
    subUrl: `/faq/${data._id}`,
    data,
  };
  return patch(request);
};

export const deleteFaq = (id) => {
  const request = {
    subUrl: `/faq/${id}`,
  };
  return deletee(request);
};

export const getFaqByID = (id) => {
  const request = {
    subUrl: `/faq/${id}`,
  };
  return get(request);
};