import { listingReducer } from "../reducers/utils/listingReducer";
import { getPackages } from "services/packages";

const { reducer, actions, types } = listingReducer({
  name: "packages",
  getApi: getPackages,
});

const packages = reducer;
export { packages, actions, types };
