import { listingReducer } from "./utils/listingReducer";
import { getAllVideoGallery } from "services/videosGallary";

const { reducer, actions, types } = listingReducer({
  name: "videosGallary",
  getApi: getAllVideoGallery,
});

const videosGallary = reducer;
export { videosGallary, actions, types };
