import { listingReducer } from "../reducers/utils/listingReducer";
import { getReviewsList } from "services/Reviews";

const { reducer, actions, types } = listingReducer({
  name: "reviews",
  getApi: getReviewsList,
});

const reviews = reducer;
export { reviews, actions, types };
