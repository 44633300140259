import React, {  useState,useEffect } from "react";

import MobileNav from "./MobileNav";

import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "./newheader.scss"
import HeaderLogo from "../../assets/images/logo.webp";
import logoBanner from "../../assets/images/logo22.png"


const NewHeader = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const resizeWindow = () => {
    setWindowWidth(window.screen.width);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", resizeWindow);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (<>
        {windowWidth > 768 ?   null : (<div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",background:"#118dff",padding:"5px"}}>
         <a href="tel:(773) 465-8500">
          <span style={{color:"white",fontSize:"20px" , fontWeight:600}} >
            CALL US (773) 465-8500
          </span>
         </a>
         </div>)}

        <header style={windowWidth > 768 ? null : { position: isScrolled ? "fixed" : "relative", top: 0, width: "100%", zIndex: 1000 }}>
          <div className="nav-area">
            <Link onClick={() => setShowMenu(false)} to="/" className="logo">
              <img src={HeaderLogo} alt={HeaderLogo}/>

            </Link>

            {/* for large screens */}
            <Navbar/>

            {/* for small screens */}
            <MobileNav windowWidth={windowWidth} showMenu={showMenu} setShowMenu={setShowMenu}/>
          </div>
        </header>
      </>

  );
};

export default NewHeader;
