/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
// import $ from "jquery";
// import "./stickySocialMedia.scss";
// import SmShare from "../../assets/images/sm-share.png";
// import SmTwitter from "../../assets/images/sm-twitter.png";
// import SmFb from "../../assets/images/sm-facebook.png";
// import SmWhastapp from "../../assets/images/sm-whatsapp.png";
import whatsappIcon from "../../assets/images/whatsappicon.png"
import CallUsImage from "../../assets/images/callus.jpeg";

import { Button, Popover } from "antd";
const content = (
  <div> 
    <div class="call-image">
      <img src={CallUsImage} alt="icon media" className="img-fluid" />
    </div>
    <p className="font-weight-500 mb-0">
      Call Us to get extra CHU $20 OFF <a href="tel:773-465-8500">773-465-8500</a>
    </p>
  </div>
);

export default function StickySocialMedia() {
  let location = useLocation();
  console.log(location.pathname,"/packages/Umrah")
  //   const handleMouseEnter = () => {};
  // useEffect(() => {
  //  var out = false;

  // $("#share").mouseenter(function () {
  //   if (out === false) {
  //     $("#fb").css({ right: "68px", top: "38px" });
  //     $("#twit").css({ right: "74px", top: "55px" });
  //     $("#pin").css({ right: "67px", top: "72px" });
  //   }
  // });
  // $("#share").mouseleave(function () {
  //   if (out === false) {
  //     $("#fb, #twit, #pin,#insta").css({ right: "-7px", top: "50px" });
  //   }
  // });

  //  $("#share").click(function () {
  //  out = !out;
  // $("div", this).toggleClass("fa fa-paper-plane");
  //$(".social-content").toggleClass("active");

  //if (out === true) {
  //  $(".social-content").addClass("active");
  // $("#fb").css({ right: "85px", top: "475px" });
  // $("#twit").css({ right: "105px", top: "515px" });
  // $("#pin").css({ right: "85px", top: "555px" });
  // $("#insta").css({ right: "50px", top: "582px" });
  //} else {
  //  $(".social-content").removeClass("active");
  // $("#fb, #twit, #pin,#insta").css({ right: "-7px", top: "460px" });
  //}
  //});
  // }, []);

  return (
    <div className="social-section">
        {/*<Button className="call-us">*/}

        <div style={location.pathname === "/packages/Umrah" ? {bottom:"8rem"} : null} className="fixedsocail">
            <Button className="call-us" href="tel:+1(773)465-8500">
                <i className="fa fa-phone"></i>
            </Button>

            <a
                href="https://wa.me/+17734658500"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className="whatsapp-icon" src={whatsappIcon} alt={whatsappIcon} />
            </a>
        </div>

        {/*       <Button className="call-us" href="tel:773-465-8500">*/}
        {/*           <i className="fa fa-phone"></i>*/}
        {/*       </Button>*/}

        {/*<a*/}
        {/*    href="https://wa.me/2348100000000"*/}
        {/*    className="whatsapp_float"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*>*/}
        {/*    <img className="whatsapp-icon" src={whatsappIcon} alt={whatsappIcon} />*/}
        {/*</a>*/}

        {/*</Button>*/}
      {/*<Popover*/}
      {/*  overlayClassName="callus-popover-model"*/}
      {/*  content={content}*/}
      {/*  title="Title"*/}
      {/*  trigger="hover"*/}
      {/*>*/}
      {/*  <Button className="call-us">*/}
      {/*    <i class="fa fa-phone"></i>*/}
      {/*  </Button>*/}
      {/*</Popover>*/}
      <div class="spinner-wrap">
        <div class="spinner-item"></div>
        <div class="spinner-item spinner-item--2"></div>
        <div class="spinner-item spinner-item--3"></div>
      </div>
      <div id="share">
         {/*<div class="fa fa-paper-plane"></div> */}
         {/*<img src={SmShare} alt="icon media" className="img-fluid" />*/}

        <div class="spinner-wrap">
          <div class="spinner-item"></div>
          <div class="spinner-item spinner-item--2"></div>
          <div class="spinner-item spinner-item--3"></div>
        </div>
      </div>
    </div>

    // <div className="share-content">
    //   <div id="share">
    //     {/* <div class="fa fa-paper-plane"></div> */}
    //     <img src={SmShare} alt="icon media" className="img-fluid"/>
    //   </div>
    //   <div id="fb" class="sm-social">
    //     <a
    //       target={"_blank"}
    //       href="https://www.facebook.com/ChicagoHajjandUmrah/"
    //     >
    //       <div class="fa fa-facebook fa-stack-1x sm-icon"></div>
    //     </a>
    //   </div>
    //   <div id="twit" class="sm-social">
    //     <a target={"_blank"} href="https://twitter.com/Chicagohajj">
    //       <div class="fa fa-twitter fa-stack-1x sm-icon"></div>
    //     </a>
    //   </div>
    //   <div id="pin" class="sm-social">
    //     <a
    //       target={"_blank"}
    //       href="https://api.whatsapp.com/send?phone=17734658500&text=%20%D8%A7%D9%84%D8%B3%D9%84%D8%A7%D9%85%20%D8%B9%D9%84%D9%8A%D9%83%D9%85%20%D9%88%D8%B1%D8%AD%D9%85%D8%A9%20%D8%A7%D9%84%D9%84%D9%87%20%D9%88%D8%A8%D8%B1%D9%83%D8%A7%D8%AA%D9%87%20I%20want%20to%20know%20about"
    //       class="pin-it-button"
    //       count-layout="horizontal"
    //     >
    //       <div class="fa fa-whatsapp fa-stack-1x sm-icon"></div>
    //     </a>
    //   </div>
    //   <div id="insta" class="sm-social">
    //     <a
    //       target={"_blank"}
    //       href="https://www.instagram.com/chicagohajjandumrah/"
    //       class="pin-it-button"
    //       count-layout="horizontal"
    //     >
    //       <div class="fa fa-instagram fa-stack-1x sm-icon"></div>
    //     </a>
    //   </div>
    // </div>
    // <div class="navigation scroll-to-top">
    //   <div
    //     onClick={() => {
    //       let menuToggle = document.querySelector(".menuToggle");
    //       menuToggle.classList.toggle("active");
    //     }}
    //     class="menuToggle"
    //   ></div>
    //   <div class="menu">
    //     <ul>
    //       <li>
    //         <a
    //           href="https://github.com/Saaim-K"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <i
    //             title="Github"
    //             class="fa-brands fa-github first-icon"
    //             style={{
    //               color: "#6d4d9c",
    //             }}
    //           ></i>
    //         </a>
    //       </li>
    //       <li>
    //         <a
    //           href="https://www.linkedin.com/in/saaim-k/"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <i
    //             title="LinkedIn"
    //             class="fa-brands fa-linkedin  second-icon"
    //             style={{
    //               color: "#0072b1",
    //             }}
    //           ></i>
    //         </a>
    //       </li>
    //       <li>
    //         <a
    //           href="https://codepen.io/saaim-k"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <i
    //             title="CodePen"
    //             class="fa-brands fa-codepen third-icon"
    //             style={{
    //               color: "black",
    //             }}
    //           ></i>
    //         </a>
    //       </li>
    //       <li>
    //         <a
    //           href="https://www.instagram.com/_saaim.khan_/"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <i
    //             title="Instagram"
    //             class="fa-brands fa-instagram fourth-icon"
    //             style={{
    //               color: "purple",
    //             }}
    //           ></i>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
  );
}
